import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export default class RhovanionClient {
  private static instance: RhovanionClient;
  private http: AxiosInstance;
  private constructor() {
    this.http = axios.create({ baseURL: process.env.VUE_APP_RHOVANION_URL });
  }

  public static getInstance(): RhovanionClient {
    if (!RhovanionClient.instance) {
      RhovanionClient.instance = new RhovanionClient();
    }
    return RhovanionClient.instance;
  }

  async find(url: string, config?: AxiosRequestConfig | undefined) {
    return await this.http.get(url, config);
  }
  async patch(
    url: string,
    data?: any,
    config?: AxiosRequestConfig | undefined
  ) {
    return await this.http.patch(url, data, config);
  }
  async post(url: string, data: any, config?: AxiosRequestConfig | undefined) {
    return await this.http.post(url, data, config);
  }
  async delete(url: string, config?: AxiosRequestConfig | undefined) {
    return await this.http.delete(url, config);
  }
}
